import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextSection from "../components/text-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const Affiliations = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ECAImg: wpMediaItem(title: { eq: "ECA-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			IDEImg: wpMediaItem(
				title: { eq: "Institution-of-Diagnostic-Engineers-Logo" }
			) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			TMImg: wpMediaItem(title: { eq: "Trustmark-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			DIImg: wpMediaItem(title: { eq: "NICEIC Approved Contractor Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			RCPImg: wpMediaItem(title: { eq: "Registered-Competent-Person-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			DImg: wpMediaItem(title: { eq: "Dimplex-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ESRImg: wpMediaItem(title: { eq: "Electrical-Safety-Register-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const ECAImg = data.ECAImg?.localFile.childImageSharp.gatsbyImageData;
	const IDEImg = data.IDEImg?.localFile.childImageSharp.gatsbyImageData;
	const DIImg = data.DIImg?.localFile.childImageSharp.gatsbyImageData;
	const TMImg = data.TMImg?.localFile.childImageSharp.gatsbyImageData;
	const RCPImg = data.RCPImg?.localFile.childImageSharp.gatsbyImageData;
	const DImg = data.DImg?.localFile.childImageSharp.gatsbyImageData;
	const ESRImg = data.ESRImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Affiliations",
				item: {
					url: `${siteUrl}/affiliations`,
					id: `${siteUrl}/affiliations`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Affiliations | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/affiliations`,
					title: "Affiliations | Denis Bonnici Electricians Ltd",
					description:
						"Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>

			<section className="py-md-7 py-4">
				<TextSection
					hideBtn={true}
					title="Affiliations"
					text={
						<div>
							<Row>
								<Col
									className="d-flex align-items-center mt-5 mt-md-none justify-content-center "
									xl={4}
									md={6}
								>
									<GatsbyImage image={ECAImg} alt={data.ECAImg?.altText} />
								</Col>
								<Col
									className="d-flex align-items-center mt-5 mt-md-none justify-content-center "
									xl={4}
									md={6}
								>
									<GatsbyImage
										className=""
										image={IDEImg}
										alt={data.IDEImg?.altText}
									/>
								</Col>
								<Col
									className=" d-flex align-items-center mt-5 mt-xl-none justify-content-center"
									xl={4}
									md={6}
								>
									<GatsbyImage image={DIImg} alt={data.DIImg?.altText} />
								</Col>

								<Col
									className="d-flex align-items-center mt-5 justify-content-center "
									xl={4}
									md={6}
								>
									<GatsbyImage image={TMImg} alt={data.TMImg?.altText} />
								</Col>
								<Col
									className="d-flex align-items-center mt-5 justify-content-center "
									xl={4}
									md={6}
								>
									<GatsbyImage
										className=""
										image={RCPImg}
										alt={data.RCPImg?.altText}
									/>
								</Col>
								<Col
									className=" d-flex align-items-center mt-5 justify-content-center"
									xl={4}
									md={6}
								>
									<GatsbyImage image={ESRImg} alt={data.ESRImg?.altText} />
								</Col>
							</Row>
							<p className="text-center mt-5">Registered Installers for</p>
							<Row className="justify-content-center">
								<Col
									className=" d-flex align-items-center mt-4 justify-content-center"
									xl={4}
									md={6}
								>
									<GatsbyImage image={DImg} alt={data.DImg?.altText} />
								</Col>
							</Row>
							<p className="text-center mt-5">
								Denis Bonnici is a proud member of the{" "}
								<span className="fw-bold">IET</span>
							</p>
							<p className="text-center">
								Click to call us now on{" "}
								<a href="tel:+441323642427">01323 642 427</a> for a free no
								obligation quote or advice on all aspects of electrical work.
							</p>
							<p className="fw-bold text-center">Safety First – Be Safe!</p>
						</div>
					}
				/>
			</section>
		</Layout>
	);
};

export default Affiliations;
